
import { defineComponent, ref } from "vue";

import { Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import ElectronicSignature from "@/components/modals/ElectronicSignature.vue";
import { Modal } from "bootstrap";

interface TransportData {
  name: string;
  cuit: string;
}

export default defineComponent({
  name: "new-address-modal",
  components: {
    ElectronicSignature,
    Field,
    Form,
  },
  methods: {
    triggerSignatureForm() {
      var modalito = new Modal(document.getElementById("sign-modal"));
      modalito.show();
    },
    catchErrors(error) {
      if (error.response.status == 451) {
        return this.triggerSignatureForm();
      }

      this.entity.electronicSignature = "";
      this.errors = error.response.data.errors;

      Swal.fire({
        text: error.response.data.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    submitFormWithSignature(signature) {
      this.entity.electronicSignature = signature;
      this.submitForm();
    },
    submitForm() {
      this.errors = {};

      if (this.entity.id) {
        ApiService.update("users", this.entity.id.toString(), this.entity)
          .then((response) => {
            this.$router.back();
          })
          .catch(this.catchErrors);
      } else {
        ApiService.post("users", this.entity)
          .then((response) => {
            this.$router.back();
          })
          .catch(this.catchErrors);
      }
    },
    getGrupos() {
      ApiService.query("index/groups", {}).then((response) => {
        this.groupList = response.data;
      });
    },
    togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
    },
  },
  data() {
    return {
      showPassword: false,
      fieldType:'text',
      errors: {},
      leaderList: [],
      transportList: [],
      groupList: [],
      supervisorList: [],
      requireReason: false,
      requireExtra: true,
      signatureType: "update",
      roleList: [],
      entity: {
        id: "",
        email: "",
        username: "",
        visitorId: null,
        leaderId: null,
        visitor_group_id: null,
        role: "",
        parentId: null,
        transportId: null,
        status: "",
        phone: "",
        firstName: "",
        allowLogin: true,
        mustChangePassword: false,
        lastName: "",
        electronicSignature: "",
        password:""
      },
    };
  },
  mounted() {
    this.getGrupos();
    ApiService.query("index/users", {
      params: { permission: ["supervise_user"] },
    }).then((response) => {
      this.supervisorList = response.data;
    });

    ApiService.query("index/users", {
      params: { permission: ["lead_user"] },
    }).then((response) => {
      this.leaderList = response.data;
    });

    ApiService.get("index/transports").then((response) => {
      this.transportList = response.data;
    });

    ApiService.get("index/roles").then((response) => {
      this.roleList = response.data;
    });

    if (this.$route.params.id) {
      const userId = this.$route.params.id.toString();
      ApiService.get("users", userId).then((response) => {
        this.entity.id = response.data.id;
        this.entity.username = response.data.username;
        this.entity.lastName = response.data.lastName;
        this.entity.firstName = response.data.firstName;
        this.entity.phone = response.data.phone;
        this.entity.visitor_group_id = response.data.visitor_group_id ? response.data.visitor_group_id.toString() :"";
        this.entity.email = response.data.email;
        this.entity.status = response.data.status;
        this.entity.role = response.data.roles[0].name;
        this.entity.transportId = response.data.transport_id
          ? response.data.transport_id.toString()
          : "";
        this.entity.parentId = response.data.parent_id
          ? response.data.parent_id.toString()
          : "";

        this.entity.leaderId = response.data.leader_id
          ? response.data.leader_id.toString()
          : "";

        this.entity.allowLogin = true;
        this.entity.mustChangePassword = false;
      });
    } else {
      this.signatureType = "create";
      this.requireReason = false;
      this.requireExtra = false;
    }
  },
});
